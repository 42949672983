export async function fetchTSVSheetData<T>(sheetUrl: string, mapper: (...parts: string[]) => T) {
    try {
      const response = await fetch(sheetUrl);
      const body = await response.text();
      const newRests = body.split('\n').slice(1).map(line => {
        line = line.trim();
        const parts = line.split('\t');
        return mapper(...parts);
      });
      return newRests;
    } catch(error) {
      console.error(error);
    }
    return [];
}