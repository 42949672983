import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

type SharedHeaderProps = {
    title: string,
}
function SharedHeader({title} : SharedHeaderProps) {
    const navigate = useNavigate();

    return (
        <div>
            <div style={{display: 'flex', gap: 20, alignItems: 'center', justifyContent: 'center'}}>
                <h3 className='App-link' onClick={() => navigate('/')}>Luqing & Josh</h3>
                <span><FontAwesomeIcon icon={faChevronRight}/></span>
                <h3>{title}</h3>
            </div>
            <hr />
        </div>
    );
}

export default SharedHeader;