import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Default from './components/default/default';
import RestaurantPicker from './components/restaurant-picker/restaurant-picker';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Default />,
  },
  {
    path: "/restaurants",
    element: <RestaurantPicker />,
  },
]);

function App() {

  return (
    <div className="App-container">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
