import { faArrowDown, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';

const pluralize = (x: number, singular: string, plural: string = singular + 's') => x === 1 ? `${x} ${singular}` : `${x} ${plural}`;

const firstDate = moment("20211204", "YYYYMMDD");
const diff = moment.duration(moment().diff(firstDate));
const years = diff.years();
const days = diff.days();

function Default() {
    const navigate = useNavigate();
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const scroll = () => {
        setTimeout(() => messagesEndRef.current?.scrollIntoView(), 200);
        
    }

    return (
        <div>
            <div className="App-header">
                <div style={{ flexGrow: 1 }}></div>
                <h1>Luqing & Josh</h1>
                <img src='/jandl.jpg' style={{ height: '30vh', borderRadius: '12vh' }}></img>
                <h4 title={`Our first date was on ${firstDate.format('LL')}`} style={{ cursor: 'help' }}>
                    Together for <br />
                    {pluralize(years, 'year')} and {pluralize(days, 'day')}  <br />
                    <small style={{fontSize: '0.5em', opacity: 0.7}}>Since {firstDate.format('LL')}</small>
                </h4>
                <div style={{ flexGrow: 1 }}></div>
                <div style={{ margin: 20, fontSize: '1em', marginBottom: 100 }} className='App-link' onClick={scroll}>
                    <small><FontAwesomeIcon icon={faArrowDown} /> Scroll down for our apps <FontAwesomeIcon icon={faArrowDown} /></small>
                </div>
            </div>
            <hr />
            <h1>Apps</h1>
            <div className='App-page-selector' ref={messagesEndRef}>
                <div className='App-page-selection' onClick={() => navigate('/restaurants')}>
                    <button className='App-button'>Restaurant Picker <br /> <small>A tool to help us decide what to eat</small> <br /></button>
                </div>
            </div>
        </div>
    );
}

export default Default;
