import { useState, useEffect } from "react";
import { fetchTSVSheetData } from "../../utility/fetcher";
import SharedHeader from "../shared/shared-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const RESTAURANTS_SHEET_URL = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSEAPm2NEWTxv3nwhH1sFlStRl8rSc86ceqqo5iDe7TpIJcWHqDCybgMe-sxdqK5SGCya9g7gcnvwDN/pub?gid=0&single=true&output=tsv';
const ALL_CUISINES = new Set<string>();

class Restaurant {
    cuisines: string[];
    isTakeOut: boolean;
    isDineIn: boolean;

    constructor(public name: string, cuisines: string, isTakeOut: string, isDineIn: string, public logo: string) {
        this.cuisines = cuisines.split(',').map(x => x.trim()).filter(x => !!x);
        this.cuisines.forEach(c => ALL_CUISINES.add(c));
        this.isTakeOut = isTakeOut?.toLowerCase() === 'true';
        this.isDineIn = isDineIn?.toLowerCase() === 'true';
    }
}

function RestaurantPicker() {
    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [filteredRestaurants, setFilteredRestaurants] = useState<Restaurant[]>([]);
    const [restaurant, setRestaurant] = useState<Restaurant>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [joshVetoes, setJoshVetoes] = useState<Set<string>>(new Set());
    const [lucVetoes, setlucVetoes] = useState<Set<string>>(new Set());

    useEffect(() => {
        getRestaurants().then();
    }, []);

    const getRestaurants = async () => {
        setIsLoading(true);
        const res =  await fetchTSVSheetData(RESTAURANTS_SHEET_URL, (name, cuisines, isTakeOut, isDineIn, logo) => new Restaurant(name, cuisines, isTakeOut, isDineIn, logo));
        setRestaurants(res);
        setIsLoading(false);
    }

    const setFilter = (name: string, filter: (x: Restaurant) => boolean) => {
        setFilterName(name);
        setFilteredRestaurants(restaurants.filter(filter));
    }

    useEffect(() => {
        if (filteredRestaurants.length > 0) {
            selectRandomRestaurant();
        }
    }, [filteredRestaurants]);

    const selectRandomRestaurant = () => {
        setRestaurant(undefined);
        setIsLoading(true);
        setTimeout(() => {
            setRestaurant(filteredRestaurants[Math.floor(Math.random() * filteredRestaurants.length)]);
            setIsLoading(false);
        }, 500)
    }

    if (isLoading) {
        return <div>
            <SharedHeader title='Restaurant Picker'/>
            <h1 style={{marginTop: '45vh'}}><FontAwesomeIcon icon={faSpinner} spin size="2xl"/></h1>
        </div>;
    }

    if (filteredRestaurants.length === 0 || !restaurant) {
        return <div>
            <SharedHeader title='Restaurant Picker'/>
            <h1>What type of restaurant do you want?</h1>
            <div style={{display: 'flex', gap: 5, margin: 10, flexDirection: 'column'}}>
                <button className="App-button" style={{flex: '1 1 0'}} onClick={() => setFilter('Any', x => true)}>Any</button>
                <button className="App-button" style={{flex: '1 1 0'}} onClick={() => setFilter('Takeout', x => x.isTakeOut)}>Takeout/Takeway</button>
                <button className="App-button" style={{flex: '1 1 0'}} onClick={() => setFilter('Dine-in', x => x.isDineIn)}>Dine-in</button>
                {[...ALL_CUISINES].sort().map(c => <button key={c} className="App-button" style={{flex: '1 1 0'}} onClick={() => setFilter(c, x => x.cuisines.includes(c))}>{c}</button>)}
            </div>
        </div>
    }

    return (
        <div>
            <SharedHeader title='Restaurant Picker'/>
            <h3>
                <em><strong>{filterName}</strong> - {filteredRestaurants.length} {filteredRestaurants.length > 1 ? 'options' : 'option'}
                <br />
                </em>
            </h3>
            <button className="App-button" onClick={() => setFilteredRestaurants([])}>Clear filter</button>
            <hr />
            <h1><p>{restaurant.name}</p></h1>
            {restaurant.logo ? <img src={restaurant.logo} style={{maxHeight: '20vh', marginBottom: 20, borderRadius: 25}}></img> : <></>}
            <hr />
            <div style={{display: 'flex', gap: 5, margin: 10, flexDirection: 'row'}}>
                <button className="App-button" style={{flex: '1 1 0'}} onClick={() => {lucVetoes.add(restaurant.name); selectRandomRestaurant()}}>Luqing Veto</button>
                <button className="App-button" style={{flex: '1 1 0'}} onClick={() => {joshVetoes.add(restaurant.name); selectRandomRestaurant()}}>Josh Veto</button>
            </div>
            <div style={{display: 'flex', gap: 5, margin: 10, flexDirection: 'row'}}>
                <div style={{flex: '1 1 0'}}>
                    {[...lucVetoes].sort().map(rName => <p key={rName}>{rName}</p>)}
                </div>
                <div style={{flex: '1 1 0'}}>
                    {[...joshVetoes].sort().map(rName => <p key={rName}>{rName}</p>)}
                </div>
            </div>
        </div>
    );
}

export default RestaurantPicker;